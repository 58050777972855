import React, { Component, Fragment } from 'react';
import ModalAddWorkflow from './ModalAddWorkflow';
import ModalEditWorkflow from './ModalEditWorkflow';
import { workflowsBDC } from '../../enums/WorkflowsBDC';
import { receptionsLeads } from '../../enums/ReceptionsLeads';

import '../../style.css';

var lodash = require('lodash')

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      familles: [],
      sousFamilles: [],
      origines: [],
      originesActives: [],
      sites: [],

      workflows: [],
      fetchingWorkflows: true,
      fetchingGpConfig: true,
      editedWorkflow: null,

      view: "origine",
      gpConfig: null,
      gpConfigUpdated: false,
    }

    this.views = {
      "site": {
        "getWorkflows": this.getWorkflowsBySite.bind(this),
        "header": "SiteLibelle",
        "rowTitle": "OrigineLibelle",
        "rowHeaderText": "Origine",
        "minWidthCol1": "700px",
      },
      "origine": {
        "getWorkflows": this.getWorkflowsByOrigine.bind(this),
        "header": "OrigineLibelle",
        "rowTitle": "SiteLibelle",
        "rowHeaderText": "Site",
        "minWidthCol1": "450px",
      },
    }
  }

  async componentDidMount() {
    this.fetchSites();
    await this.fetchOrigines();
    this.fetchWorkflows();
    this.fetchGpConfig();
  }

  async fetchOrigines() {
    await fetch(localStorage.backoffice_api + '/api/origines', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      var origines = lodash.filter(json, o => {
        return (o.Type_Description == "VN" || o.Type_Description == "VO" || o.Type_Description == "VDL");
      });
      origines = lodash.sortBy(origines, o => {
        return o.Libelle + o.Type_Description;
      })

      var originesActives = lodash.filter(origines, (o) => {
        return o.EstInactif != 1;
      })

      var familles = lodash.map(originesActives, (o) => {
        return {
          IDFamilleOrigineContact: o.IDFamilleOrigineContact,
          Libelle: o.Famille_Libelle
        };
      })
      familles = lodash.uniqBy(familles, (f) => {
        return f.IDFamilleOrigineContact;
      })
      familles = lodash.sortBy(familles, (f) => {
        return f.Libelle;
      })

      var sousFamilles = lodash.map(originesActives, (o) => {
        return {
          IDSousFamilleOrigineAction: o.IDSousFamilleOrigineAction,
          Libelle: o.SousFamille_Libelle,
          IDFamilleOrigineContact: o.IDFamilleOrigineContact,
        };
      })
      sousFamilles = lodash.uniqBy(sousFamilles, (f) => {
        return f.IDSousFamilleOrigineAction;
      })
      sousFamilles = lodash.sortBy(sousFamilles, 'Libelle');

      this.setState({
        origines: origines,
        originesActives: originesActives,
        familles: familles,
        sousFamilles: sousFamilles
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchSites() {
    fetch(localStorage.backoffice_api + '/api/sites', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      
      var sites = lodash.filter(json, site => site.Est_eSellerLeadActif && !site.EstFictif);
      lodash.each(sites, (site) => {
        if(site.Plaque_Libelle != site.Libelle) {
          site.Libelle = site.Plaque_Libelle + " - " + site.Libelle;
        }
      })
      sites = lodash.sortBy(sites, (site) => {
        return site.Libelle;
      })
      
      this.setState({
        sites: sites,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchWorkflows() {
    this.setState({
      fetchingWorkflows: true
    })

    fetch(localStorage.backoffice_api + '/api/workflowsLeads', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      lodash.each(json, workflow => {
        workflow.OrigineLibelle = this.getOrigineLibelle(workflow.IDOrigineAction)
        if(workflow.IDSite == null) {
          workflow.SiteLibelle = " Sites sans configuration spécifique";
        }
        else if(workflow.PlaqueLibelle != workflow.SiteLibelle) {
          workflow.SiteLibelle = workflow.PlaqueLibelle+" - "+workflow.SiteLibelle;
        }
      });

      var workflows = lodash.sortBy(json, workflow => workflow.OrigineLibelle + workflow.SiteLibelle);

      this.setState({
        workflows: workflows,
        fetchingWorkflows: false
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchGpConfig() {
    fetch(localStorage.backoffice_api + '/api/workflowsLeads/gpConfig', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        gpConfig: json,
        fetchingGpConfig: false,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  deleteWorkflow(IDWorkflowLead) {
    if(!window.confirm("Supprimer définitivement cette configuration ?"))
      return;

    fetch(localStorage.backoffice_api + '/api/workflowsLeads/'+IDWorkflowLead, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.JWT,
        }
    })
    .then((response) => {
        if (!response.ok) {
          response.json().then((value) => {
            alert(value.message)
          });
          this.setState({
            saving: false,
          })
          throw Error(response);
        }

        var workflows = lodash.filter(this.state.workflows, d => d.IDWorkflowLead != IDWorkflowLead);

        this.setState({
          workflows: workflows
        })
    })
    .catch((error) => {
        console.log(error)
    });
  }

  saveGpConfig() {
    fetch(localStorage.backoffice_api + '/api/workflowsLeads/gpConfig', {
      method: 'PATCH',
      headers: {
          "Content-Type": "application/json",
          authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.gpConfig)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
            alert(value.message)
        });
        throw Error(response);
      }

      this.setState({
        gpConfigUpdated: false
      })
    })
    .catch((error) => {
        console.log(error)
    });
  }

  showModalEdit(IDWorkflowLead) {
    var workflow = lodash.find(this.state.workflows, d => d.IDWorkflowLead == IDWorkflowLead);

    this.setState({
      editedWorkflow: workflow
    }, () => {
      window.$("#modalEditWorkflow").modal("show");
    })
  }

  changeView(view) {
    this.setState({
      view: view
    });
  }

  onWorkflowUpdated(workflow) {
    var workflows = this.state.workflows;
    var idx = lodash.findIndex(workflows, d => d.IDWorkflowLead == workflow.IDWorkflowLead);
    workflows[idx] = workflow;

    this.setState({
      workflows: workflows
    })
    window.$("#modalEditWorkflow").modal("hide");
  }

  onDefaultProcessBDCUpdated(e) {
    var gpConfig = this.state.gpConfig;
    gpConfig.GRP_eSellerLeadProcessBDC = e.target.value;

    this.setState({
      gpConfig: gpConfig,
      gpConfigUpdated: true
    })
  }

  onDefaultLeadReceptionUpdated(e) {
    var gpConfig = this.state.gpConfig;
    gpConfig.GRP_ReceptionLeads = e.target.value;

    this.setState({
      gpConfig: gpConfig,
      gpConfigUpdated: true
    })
  }

  getWorkflowsByOrigine() {
    return lodash.values(lodash.groupBy(this.state.workflows, "OrigineLibelle"));
  }

  getWorkflowsBySite() {
    var workflows = lodash.sortBy(this.state.workflows, d => d.SiteLibelle + d.OrigineLibelle)
    return lodash.values(lodash.groupBy(workflows, "SiteLibelle"));
  }

  getOrigineLibelle(IDOrigineAction) {
    var origine = lodash.find(this.state.origines, (o) => o.IDOrigineAction == IDOrigineAction);

    return origine.Famille_Libelle+" / "+origine.SousFamille_Libelle+" / "+origine.Libelle+" ("+origine.Type_Description+")";
  }

  getReceptionLeadsLibelle(code) {
    return lodash.find(receptionsLeads, {"code": code}).libelle;
  }

  getWorkflowBDCLibelle(code) {
    if(!code) {
      return lodash.find(workflowsBDC, {"code": this.state.gpConfig.GRP_eSellerLeadProcessBDC}).libelle+" (config de transfert par défaut du groupe)";
    }
    return lodash.find(workflowsBDC, {"code": code}).libelle;
  }

  renderWorkflows() {
    const display = this.views[this.state.view];
    const workflows = display.getWorkflows();

    return workflows.map((workflowsGroup, i) => {
      return (
        <div key={i}>
          <div className="p-2 bg-eseller-color">{workflowsGroup[0][display.header]}</div>
          <div className="d-flex flex-column ml-2 mr-2 w-auto" style={{borderLeft: '2px solid #9A0059', borderRight: '1px solid #9A0059', borderBottom: '1px solid #9A0059'}}>
            <div className="d-flex flex-row p-2 font-weight-bold" style={{color:'#9A0059'}}>
              <div className="my-auto" style={{minWidth: display.minWidthCol1, whiteSpace:"nowrap"}}>
                {display.rowHeaderText}
              </div>
              <div className="my-auto" style={{minWidth:"350px", whiteSpace:"nowrap"}}>
                Leads réceptionnés par
              </div>
            </div>
            {workflowsGroup.map((workflow, j) => {
              return (
                <div key={j} className="d-flex flex-row p-2" style={{backgroundColor: j%2 == 0 ? "#F0F0F0" : "#FFFFFF"}}>
                  <div className="my-auto" style={{minWidth: display.minWidthCol1, whiteSpace:"nowrap"}}>
                    {workflow[display.rowTitle]}
                  </div>
                  <div className="my-auto" style={{minWidth:"350px", whiteSpace:"nowrap"}}>
                    &#x27A4; {this.getReceptionLeadsLibelle(workflow.ReceptionLeads)}
                    {workflow.ReceptionLeads == "BDC" ? 
                      <span>&nbsp;qui transfèrent vers {this.getWorkflowBDCLibelle(workflow.WorkflowBDC)}</span>
                    :null}
                  </div>
                  <div className="w-100 d-flex justify-content-end">
                    <button type="button" className='btn btn-primary btn-sm my-auto' onClick={this.showModalEdit.bind(this, workflow.IDWorkflowLead)}>
                      <i className="fa fa-pencil"></i>
                    </button>
                    &nbsp;
                    <button type="button" className='btn btn-danger btn-sm my-auto' onClick={this.deleteWorkflow.bind(this, workflow.IDWorkflowLead)}>
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    })
  }

  render() {

    const nbWorkflows = this.state.workflows.length;
    const nbOriginesConcernees = lodash.uniqBy(this.state.workflows, "IDOrigineAction").length;
    
    return (
      <div className='container-fluid'>
        <ModalAddWorkflow 
          familles={this.state.familles} 
          sousFamilles={this.state.sousFamilles} 
          origines={this.state.originesActives}
          sites={this.state.sites}
          receptionsLeads={receptionsLeads}
          workflowsBDC={workflowsBDC}
          alreadyExistingWorkflows={this.state.workflows}/>
        <ModalEditWorkflow
          ref="ModalEditWorkflow"
          workflow={this.state.editedWorkflow}
          receptionsLeads={receptionsLeads}
          workflowsBDC={workflowsBDC}
          onWorkflowUpdated={this.onWorkflowUpdated.bind(this)}/>
        <div className="row">
          <div className="col">

            <h2>Origines : Workflows</h2>

            {this.state.fetchingGpConfig ? 
              <div className="d-flex align-items-center justify-content-center mt-5">
                <i className='fa fa-spinner fa-spin fa-fw fa-2x'></i>
              </div>
            :!this.state.gpConfig || !this.state.gpConfig.GRP_BDCTraitementLeadAmontChefVente ? 
              <div className="d-flex align-items-center justify-content-center mt-5">
                L'option GRP_BDCTraitementLeadAmontChefVente doit être activée pour pouvoir configurer des workflows par origine
              </div>
            :
              <>
                <div className="row mb-2">
                  <div className="col">
                    <div className="alert alert-primary">
                      <h4>Workflow par défaut</h4>
                      Les origines n'ayant pas de workflow configuré utiliseront ce workflow :
                      <div className="d-flex flex-column mt-2" style={{gap: 4}}>
                        <div>
                          &#x27A4; Les leads sont réceptionnés par les 
                          <select onChange={this.onDefaultLeadReceptionUpdated.bind(this)} value={this.state.gpConfig.GRP_ReceptionLeads ?? 'BDC'} id='GRP_ReceptionLeads' name="GRP_ReceptionLeads" className="form-control form-control-sm col-sm-3 d-inline-block w-auto mx-1">
                            {receptionsLeads.map((r) => {
                              return <option value={r.code} key={r.code}>{r.libelle}</option>
                            })}
                          </select>
                        </div>
                        <div>
                          &#x27A4; Les BDC peuvent transférer vers
                          <select onChange={this.onDefaultProcessBDCUpdated.bind(this)} value={this.state.gpConfig.GRP_eSellerLeadProcessBDC ?? ''} id='GRP_eSellerLeadProcessBDC' name="GRP_eSellerLeadProcessBDC" className="form-control form-control-sm col-sm-3 d-inline-block w-auto mx-1">
                            {!this.state.gpConfig.GRP_eSellerLeadProcessBDC ? <option value=''></option> : null}
                            {workflowsBDC.map((t) => {
                              return <option value={t.code} key={t.code}>{t.libelle}</option>
                            })}
                          </select>
                        </div>
                      </div>

                      {this.state.gpConfigUpdated ? 
                        <div className="mt-1 d-flex justify-content-center">
                          <button onClick={this.saveGpConfig.bind(this)} className="btn btn-sm ml-1 btn-primary">
                            Enregistrer
                          </button>
                        </div>
                      :null}
                    </div>
                  </div>
                </div>
                
                <div className="row mb-2">
                  <div className="col">
                    <button type='button' className='btn btn-light' data-toggle="modal" data-target="#modalAddWorkflow">
                      <i className='fa fa-plus'></i> Configurer un workflow
                    </button>
                  </div>
                  <div className="col align-items-center justify-content-end d-flex flex-row">
                    <div className="mr-2">
                      Vue : 
                    </div>
                    <div className="btn-group" role="group">
                      <button type="button" className={"btn " + (this.state.view == "origine" ? "btn-eseller-color" : "btn-light")} onClick={this.changeView.bind(this, "origine")}>Origine</button>
                      <button type="button" className={"btn " + (this.state.view == "site" ? "btn-eseller-color" : "btn-light")} onClick={this.changeView.bind(this, "site")}>Site</button>
                    </div>
                  </div>
                </div>

                {this.state.fetchingWorkflows ? 
                  <div className="row">
                    <div className="col d-flex justify-content-center align-items-center">
                      <i className='fa fa-spinner fa-spin fa-fw fa-2x'></i>
                    </div>
                  </div>
                :!this.state.workflows.length ? 
                  <div className="d-flex align-items-center justify-content-center mt-5">Aucun workflow n'est configuré actuellement</div>
                : 
                  <div>
                    <div className="alert alert-info">
                      {nbWorkflows} {nbWorkflows > 1 ? "workflows configurés" : "workflow configuré"} ({nbOriginesConcernees} {nbOriginesConcernees > 1 ? "origines concernées" : "origine concernée"})<br/>
                    </div>
                    <div className="d-flex flex-column mb-4">
                      {this.renderWorkflows()}
                    </div>
                  </div>
                }
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
